.embed-dialog {
  width: 600px;
}

.embed-dialog button {
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  font-size: 14px;
  padding: 10px 15px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 5px;
}

.embed-dialog button:hover {
  background-color: rgb(221, 221, 221);
}

.embed-dialog .buttons-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
}

.typeahead-popover {
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin-top: 25px;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  background: #eee;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  background-color: #eee;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.auto-embed-menu {
  width: 150px;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-wrapper input {
  display: flex;
  font-size: 16px;
  min-width: 0px;
  flex: 2 1 0%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(153, 153, 153);
  border-image: initial;
  padding: 7px 10px;
  border-radius: 5px;
}

.embed-block {
  user-select: none;
}

.embed-block-focus {
  outline: 2px solid rgb(60, 132, 244);
}
