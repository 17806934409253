.main_block {
    border-radius: 10px;
    border: 1px solid lightgray;
    text-align: center;
    width: fit-content !important
}

.inputs_block.MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 40px;
}
