.container {
    color: black;
}
.metadata {
    min-width: 40vw;
    margin: 0 20px;
    padding: 0;
    display: flex;
    flex-direction: row;
}
.metadata li {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-weight: 700;
    width: 33%;
}
.metadata li span {
    max-width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.metadata li span.success {
    color: green;
}
.metadata li span.warning {
    color: orange;
}
.metadata li span.error {
    color: red;
}
.progress {
    margin: 0 20px;
}
.input-value {
    width: 100%;
    margin: 0;
    -webkit-appearance: none;
    border: none;
    outline: none;
    background-color: #6165f8;
}
.input-value::-webkit-slider-runnable-track {
    height: 6px;
}
.input-value::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-radius: 100%;
    background-color: #6165f8;
    border: 2px solid white;
    height: 22px;
    width: 22px;
    margin-top: -7px;
    position: relative;
    z-index: 2;
}
.preview-container {
    margin: auto;
    position: relative;
    margin-top: 30px;
    font-weight: 700;
    max-width: 80vw;
    max-height: 50vh;
}
#slider-input {
    width: calc(100% + 40px);
    position: absolute;
    left: -20px;
    top: -6px;
    -webkit-appearance: none;
    outline: none;
    z-index: 1;
    cursor: pointer;
}
#slider-input::-webkit-slider-runnable-track {
    height: 0px;
}
#slider-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #6165f8;
}
.compressed,
.original {
    position: absolute;
    top: -25px;
    margin-left: 10px;
    color: black;
}
.original {
    right: 10px;
}
.top {
    display: flex;
    align-items: stretch;
    overflow: hidden;
    width: 50%;
    border-right: 2px solid #6165f8;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}
.bottom {
    width: 100%;
    height: 100%;
    max-height: inherit;
}
.bottom img {
    width: 100%;
    max-height: inherit;
    z-index: -1;
}
