button {
  background-color: rgb(238, 238, 238);
  cursor: pointer;
  font-size: 14px;
  padding: 10px 15px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 5px;
}

button:hover {
  background-color: rgb(221, 221, 221);
}

.buttons-container, .url-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.input-wrapper label {
  display: flex;
  color: rgb(102, 102, 102);
  flex: 1 1 0%;
}

.input-wrapper input {
  display: flex;
  font-size: 16px;
  min-width: 0px;
  flex: 2 1 0%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(153, 153, 153);
  border-image: initial;
  padding: 7px 10px;
  border-radius: 5px;
}